import React, { useEffect, useRef, useState } from 'react';
import { Button, Header, Image, Placeholder } from 'react-frontier';
import { Sticky } from 'semantic-ui-react';
import { Product, Breadcrumbs } from '../components';
import { ShopPayload, WebCategory } from '../ClassesPublic';
import { CDN_URL } from '../ConfigPublic';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { randomRange } from '../UtilPublic';
import { useTitle, useWebdata } from '../HooksPublic';

import API from '../APIPublic';
import slugify from 'slugify';
import classNames from 'classnames';

import '../style/shop.scss';
const PLACEHOLDER_AMOUNT = 6;

interface FilterProps{
	placeholder?: boolean,
	name?: string,
	filters?: {
		text: string,
		value: any,
	}
}

var Filter = (props: FilterProps)=>{
	if(props.placeholder){
		return (
			<div className="filter placeholder">
				<div className="title"></div>
				<div className="items">
					<div className="item"></div>
					<div className="item"></div>
					<div className="item"></div>
				</div>
			</div>
		)
	}
	return (
		<div className="filter">
			<div className="title">Categoría</div>
			<div className="items">
				<div className="item active">Chocolate original</div>
				<div className="item">Regalos</div>
				<div className="item">Fresas</div>
				<div className="item active">
					Valentines
					<i className="remove icon"></i>
				</div>
			</div>
		</div>
	)
}

interface CategoryProps{
	category?: WebCategory,
	placeholder?: boolean,
	placeholderActive?: boolean,
	color?: string,
	image?: string,
	href?: string,
	onClick?: (category_id: number)=>void,
}
var Category = (props: CategoryProps)=>{
	var [randomPlaceholder, setRandomPlaceholder] = useState<number>(randomRange(40, 87));
	if(props.placeholder){
		return <div className={classNames('co category card placeholder', { active: props.placeholderActive })}>
			<div className="image"></div>
			<div className="content">
				<div className="placeholder" style={{ width: `${randomPlaceholder}%` }}></div>
				<div className="placeholder" style={{ width: `${randomPlaceholder-20}%` }}></div>
			</div>
		</div>
	}

	const Element = props.href ? Link : 'div'

	return <Element 
		className="co category card"
		to={props.href}
		onClick={()=>{
			if(props.onClick) props.onClick(props.category.category_id);
		}}
	>
		<div className="image">
			<Image src={`${CDN_URL}/categories/${props.category.category_id}.webp`} fallback='/placeholder_portrait.webp' />
			<div className="overlay" style={{ backgroundColor: '#151717' }}></div>
		</div>
		<div className="content">
			<div className="name">
				{props.category.category_name}
			</div>
		</div>
	</Element>
}

interface ShopProps{
	id?: number,
	search?: boolean,
}

var Shop = (props: ShopProps)=>{
	var [data, setData] = useState<ShopPayload>(null);
	var [animTimer, setAnimTimer] = useState<number>(null);
	var [categories, setCategories] = useState<WebCategory[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [query] = useSearchParams();
	var params = useParams<{ category: string }>();
	var bodyContext = useRef(null);
	var webdata = useWebdata();
	var { setTitle } = useTitle();
	
	useEffect(()=>{
		setTitle('Shop');
		if(!categories) getCategories();
	}, []);

	useEffect(()=>{
		var intvl : NodeJS.Timer = null;
		var timer = 0;
		if(!categories || (params && params.category && !data)){
			intvl = setInterval(()=>{
				timer++;
				if(timer>=PLACEHOLDER_AMOUNT) timer = 0;
				setAnimTimer(timer);
			}, 250);
		}
		return ()=>{
			if(intvl) clearInterval(intvl);
		}
	}, [data, categories, params.category]);

	useEffect(()=>{
		setData(null);
		if(params?.category){
			getShopData();
		}
	}, [params.category])

	useEffect(()=>{
		setData(null);
		if(props.id){
			getShopData();
		}
	}, [props.id]);

	useEffect(()=>{
		if(!props.search) return;
		setData(null);
		getShopData();
	}, [query]);

	var getCategories = ()=>{
		if(categories) return;
		API.getAllCategories().then(res=>{
			if(res.error) return setLoadError(res.message);
			setCategories(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando las categorías (LCL-1)');
		});
	}

	var getShopData = ()=>{
		var category_id: number = null;
		if(props.id){
			category_id = props.id;
		}else{
			var reg = /(?<id>[0-9]+)\-(?<name>.*)/gi;
			var reg_res = reg.exec(params.category);
			if(reg_res && reg_res.groups?.id){
				category_id = parseInt(reg_res.groups.id);
				if(Number.isNaN(category_id)){
					category_id = null;
				}
			}
		}

		var search = query && query.has('q') ? query.get('q') : null;

		API.getShopData(category_id ? [category_id] : null, (search || null), 0, 999).then(res=>{
			if(res.error) return setLoadError(res.message);
			setData(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando los productos (LCL-2)');
		});
	}

	var Sidebar = <div className="co sidebar">
		<Filter placeholder />
	</div>

	var current_category : WebCategory = null;
	if((params.category || props.id) && categories){
		if(params.category==='all'){
			current_category = {
				category_id: -1,
				category_name: 'Shop All',
				priority: 0,
			}
		}else{
			if(props.id){
				current_category = {
					category_id: props.id,
					category_name: null,
					priority: 0
				}
			}else{
				var reg = /(?<id>[0-9]+)\-(?<name>.*)/gi;
				var reg_res = reg.exec(params.category);
				if(reg_res && reg_res.groups?.id && reg_res.groups?.name){
					var cat = categories.find(a=>a.category_id==parseInt(reg_res.groups.id));
					if(cat){
						current_category = cat;
					}
				}
			}
		}
	}

	var show_categories = current_category===null && !query.has('q');
	var section_name = query.has('q') ? `Busqueda: "${query.get('q')}"` : (current_category?.category_name || 'Shop COCOAN')
	
	return <div className="co shop" ref={bodyContext}>
		{!webdata ? (
			<div className="announcement">
				<Placeholder random minWidth={200} />
			</div>
		) : (
			<div className="announcement">
				{webdata.variables.announcement}
			</div>
		)}
		{!show_categories && (
			<Sticky offset={60} context={bodyContext}>
				<div className="header">
					<div className="contents">
						<div className="name">
							{params.category && !categories ? (
								<Placeholder width={147} random />
							) : (
								<Breadcrumbs items={[
									{ text: 'Categorías', as: Link, to: '/shop' },
									{ text: section_name },
								]} />
							)}
							{!!data && !!data.products && (
								<div className="products">
									{data.products.length} Productos
								</div>
							)}
						</div>
						{/* <Button text="Filtros" color="yellow" size="tiny" iconName="filter" /> */}
					</div>
				</div>
			</Sticky>
		)}
		<div className="contents">
			{loadError ? (
				<Header text='Error' subtext={loadError} />
			) : show_categories ? (
				<div className="categories">
					{categories ? <>
						{/* <Link className="co category card all" to="/shop/all">
							<div className="image"></div>
							<div className="content">
								<div className="name">Shop <br /> All</div>
							</div>
						</Link> */}
						{categories.map(a=>(
							<Category key={`ctg-${a.category_id}`} category={a} color='#151717' href={`/shop/${a.category_id}-${slugify(a.category_name).toLowerCase()}`} onClick={()=>window.scrollTo(0,0)} />
						))}
					</> : (
						new Array(PLACEHOLDER_AMOUNT).fill(0).map((a, i)=>(
							<Category key={`plh-ctg-${i}`} placeholder placeholderActive={i==animTimer} />
						))
					)}
				</div>
			) : <>
				{/* <Sticky offset={112} context={bodyContext}>
					{Sidebar}
				</Sticky> */}
				{!!data && !!data.page_title && !!data.page_description && (
					<div className="title">
						<div className="header">{data.page_title}</div>
						<pre className="description">{data.page_description}</pre>
					</div>
				)}
				<div className="products">
					{data && data.products.length !== 0 ? (
						data.products.map(a=>(
							<Product key={`prd-${a.product_id}`} product={a} link />
						))
					) : data && data.products.length === 0 ? (
						<Header centered className='empty' text='No se encontraron productos' iconName='frown-open' style={{ width: '100%' }} />
					) : (
						new Array(PLACEHOLDER_AMOUNT).fill(0).map((a, i)=>(
							<Product key={`plh-prd-${i}`} product={a} placeholder placeholderActive={i==animTimer} />
						))
					)}
				</div>
			</>}
		</div>
	</div>
}

export default Shop;