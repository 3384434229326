import React, { useEffect } from 'react';
import { useTitle } from '../HooksPublic';
import { Header, Button } from 'react-frontier';

import '../style/terms.scss'
import { Link } from 'react-router-dom';

var TermsView = ()=>{
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle('Términos');
	}, []);
	
	return <div className='co terms-screen'>
    <div className='main-container'>
	 	<Header text='Políticas de Compra' className='main-header'/>
		<p>Para que tu compra sea validada, tendrá que pasar por el check-out de nuestra tienda en línea (<a href="http://www.cocoan.mx">www.cocoan.mx</a>) y generar un número de confirmación.</p>
		<p>La orden de confirmación que recibirás en tu correo no constituye un contrato y tu pedido podría ser cancelado en cualquier momento debido a la incapacidad de autorizar tu forma de pago, la sospecha de intención fraudulenta, el descubrimiento de un precio de artículo incorrecto o cualquier otra razón que consideremos válida.</p>
		<p>Todas las compras realizadas en nuestra tienda en línea pasan por el sistema de cobro Openpay. Puedes realizar el pago con tarjeta de crédito (Visa, Mastercard o American Express), tarjeta de débito, o mediante transferencia bancaria (SPEI).</p>
		<p>Si tu forma de pago es en efectivo, tu pedido no será enviado hasta que realices el pago total del mismo y nuestro sistema lo valide.</p>
		<Header text="Política de envío" className="main-header"/>
		<p>Una vez autorizada la compra, recibirás un correo que te notificará que tu pedido ha sido enviado con la información correspondiente al método y tiempo de entrega. Si tu dirección de entrega se encuentra dentro de Monterrey y su área metropolitana, realizaremos la entrega por medio de un mensajero propio por un costo de $85 (ochenta y cinco pesos mexicanos). Si realizas tu compra antes de las 2 pm de lunes a sábado, procesaremos tu envío el mismo día. Todas las compras realizadas después de las 2 pm serán procesadas al siguiente día hábil.</p>
		<p>Si tu dirección de entrega se encuentra fuera de Monterrey, Nuevo León, realizaremos el envío por medio de Estafeta con un costo de $155 (ciento cincuenta y cinco pesos mexicanos) para envío de 3 a 6 días hábiles. Cocoan Chocolatería respalda todos los envíos y nos aseguramos de que lleguen a su destino, así como también ofrecemos apoyo con la paquetería con la que realizamos el envío.</p>
		<p>Para asegurar la frescura de nuestros productos, solamente realizamos envíos nacionales por paquetería de lunes a miércoles con el fin de evitar que los paquetes se queden durante el fin de semana en bodega y sufran cambios de temperatura que puedan afectar sus características.</p>
		<p>Ofrecemos envío gratis en cualquiera opción (local o nacional) en compras mayores a $750 MXN.</p>
		<Header text="Recoger en tienda" className="main-header"/>
		<p>En caso de que selecciones la opción de “Recoger en tienda” al hacer tu compra, toma en cuenta que este servicio solamente está disponible para nuestra tienda en Monterrey, Nuevo León.</p>
		<p>De igual manera recibirás un correo de confirmación y una vez validado el pago podrás pasar a recoger tu paquete en nuestra tienda ubicada en Río Manzanares 425, Colonia del Valle, San Pedro Garza García, Nuevo León. El horario para recoger tu pedido es:</p>
		<p><b>Lunes a Viernes:</b> 12 pm a 7 pm</p>
		<p><b>Sábados:</b> 12 pm a 5 pm</p>
		<p>Tienes máximo dos días hábiles para recoger tu pedido, de lo contrario éste será cancelado. Si tu pedido contiene productos perecederos (ej. fresas con chocolate) y no lo recoges después de dos días de haber recibido tu confirmación de compra, dichos productos no serán reembolsados.</p>
		<p>Si cambias de opinión y deseas que tu pedido sea enviado a tu domicilio, puedes enviarnos un correo a <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a> para que te apoyemos en el proceso. Se te enviará un link de pago por el envío y una vez confirmado este pago, procesaremos tu pedido en los tiempos antes mencionados.</p>
		<p>En caso de elegir la opción de pago en efectivo, es necesario que lo realices a través de las opciones presentadas por Openpay antes de recoger tu pedido y llevar tu recibo de pago. De no presentar un recibo de pago válido, tu orden no será entregada.</p>
		<p>Si deseas que alguien más reciba tu pedido, por favor envíanos un correo electrónico con tu número de orden, tu nombre completo y el nombre completo de la persona que pasará a recoger el pedido a nuestra tienda.</p>
		<Header text="Devoluciones" className="main-header"/>
		<p>No se aceptan devoluciones salvo que el producto llegue dañado a tu domicilio. El uso de los productos adquiridos en Cocoan Chocolatería es responsabilidad del consumidor. Cocoan Chocolatería no se hace responsable de las consecuencias secundarias que pudieran ocasionarse con el consumo de los productos. Nuestros productos son elaborados con el mejor control de calidad posible, sin embargo, no nos hacemos responsables por reacciones alérgicas a la soja y rastros de leche o nueces que pudiese contener el chocolate. Favor de tomar las consideraciones necesarias al efectuar tu compra.</p>
		<p>Si seleccionas la opción de “Recoger en tienda” por favor revisa que tu pedido esté correcto y completo antes de salir de nuestra tienda, ya que en este caso no nos hacemos responsables de los productos una vez entregado el pedido.</p>
		<Header text="Cancelaciones" className="main-header"/>
		<p>Una vez que se haya efectuado el pago de tu pedido, no hay cancelaciones. Si tienes algún problema o deseas modificar tu pedido, puedes contactarnos por correo a <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a> y nuestro equipo te ayudará a resolverlo lo mejor posible.</p>
		<Header text="Dudas o aclaraciones sobre tu pedido" className="main-header"/>
		<p>Por el momento, el único medio oficial de contacto para cualquier aclaración sobre tu compra es directamente a <a href="mailto:hola@cocoan.mx">hola@cocoan.mx</a>. Te pedimos que al escribirnos siempre agregues tu número de orden para poder brindarte el mejor y más eficiente servicio.</p>
		<Header text="Políticas de seguridad" className="main-header"/>
		<p>Cocoan Chocolatería utilizará tus datos personales recabados en nuestra página web para proveer los servicios y productos que has solicitado, así como también para mantenerte informado de nuestros productos, servicios y promociones.</p>
		<Header text="Precios" className="main-header"/>
		<p>Los precios de los productos adquiridos en Cocoan están sujetos a cambios sin previo aviso.</p>
		<div className='button-container'>
			<Button text='Aviso de privacidad' iconName='file-pdf' size="big" as={Link} to={'https://cocoan.mx/assets/COCOAN-AVISO-2018.pdf'} />
		</div>
    </div>
	</div>
}

export default TermsView;