import { createContext, PropsWithChildren, useContext, useEffect } from "react";

interface GtagItem{
	item_id: string,
	item_name: string,
	item_variant?: string,
	item_category?: string,
	price: number,
	quantity: number,
}

// ===== CHECKOUT =====
interface PixelCheckoutData{
	content_ids: any[],
	currency: string,
	eventref?: string,
	num_items: number,
	value: number,
}

interface GtagCheckoutData{
	currency: string,
	value: number,
	items: GtagItem[]
}

interface TrackerCheckoutData{
	transaction_id?: string,
	currency: string,
	value: number,
	items: GtagItem[]
}

interface PixelItemContents{
	id: any,
	quantity: number,
}

// ===== VIEW ITEM/CONTENTS =====
interface GtagViewItem{
	currency: string,
	value: number,
	items: GtagItem[],
}

type PixelContentType = 'product' | 'product_group';
interface PixelViewContent{
	content_ids: any[],
	content_category?: string,
	content_name: string,
	content_type?: PixelContentType,
	contents?: PixelItemContents[]
	currency: string,
	value: number,
}

interface TrackerViewContent{
	content_name: string,
	content_category?: string,
	content_type?: PixelContentType,
	currency: string,
	value: number,
	items: GtagItem[]
}


// ===== ADD TO CART =====
interface GtagAddCart{
	currency: string,
	value: number,
	items: GtagItem[],
}

interface PixelAddCart{
	content_ids: any[],
	content_name: string,
	content_type: PixelContentType,
	contents?: PixelItemContents[],
	currency: string,
	value: number,
}

interface TrackerAddCart{
	content_name: string,
	content_category?: string,
	content_type?: PixelContentType,
	currency: string,
	value: number,
	items: GtagItem[]
}


// ===== PURCHASE =====
interface GtagPurchase{
	currency: string,
	value: number,
	transaction_id: string,
	coupon?: string,
	shipping: number,
	items: GtagItem[],
}

interface PixelPurchase{
	content_ids: any[],
	content_name: string,
	content_type: PixelContentType,
	contents: PixelItemContents[],
	currency: string,
	num_items: number,
	value: number,
}

interface TrackerPurchase{
	value: number,
	shipping: number,
	currency: string,
	transaction_id: string,
	content_name: string,
	coupon?: string,
	items: GtagItem[],
}

// ===== PAYMENT =====
interface GtagPaymentInfo{
	currency: string,
	value: number,
	coupon?: string,
	payment_type: string,
	items: GtagItem[],
}
interface PixelPaymentInfo{
	content_category?: string,
	content_ids: any[],
	contents: PixelItemContents[],
	currency: string,
	value: number,
}

interface TrackerPaymentInfo{
	transaction_id: string,
	content_name?: string,
	payment_type: string,
	content_category?: string,
	coupon?: string,
	currency: string,
	value: number,
	items: GtagItem[]
}

var TrackerContext = createContext<{
	initiateCheckout: (data: TrackerCheckoutData)=>void,
	viewContent: (data: TrackerViewContent)=>void,
	addToCart: (data: TrackerAddCart)=>void,
	purchase: (data: TrackerPurchase)=>void,
	addPaymentInfo: (data: TrackerPaymentInfo)=>void,
}>(null);
var useTracker = () => useContext(TrackerContext);

interface TrackerProps extends PropsWithChildren{
	pixelId?: string,
	gtag?: string,
}

var TrackerProvider = (props: TrackerProps) => {
	useEffect(()=>{
		var pixel_script : any = null, pixel_noscript : any = null;
		if(props.pixelId){
			pixel_script = document.createElement('script');
			pixel_script.innerHTML = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${props.pixelId}'); fbq('track', 'PageView');`
	
			pixel_noscript = document.createElement('noscript');
			pixel_noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${props.pixelId}&ev=PageView&noscript=1" />`
	
			document.head.appendChild(pixel_script);
			document.head.appendChild(pixel_noscript);
		}

		var gtag_script : HTMLScriptElement, gtag_init_script : HTMLScriptElement;
		if(props.gtag){
			gtag_script = document.createElement('script');
			gtag_script.setAttribute('async', 'true');
			gtag_script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${props.gtag}`);

			gtag_init_script = document.createElement('script');
			gtag_init_script.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${props.gtag}');`

			document.head.appendChild(gtag_script);
			document.head.appendChild(gtag_init_script);
		}

		return ()=>{
			if(pixel_script) document.head.removeChild(pixel_script);
			if(pixel_noscript) document.head.removeChild(pixel_noscript);

			if(gtag_script) document.head.removeChild(gtag_script);
			if(gtag_init_script) document.head.removeChild(gtag_init_script);
		}
	}, []);

	function initiateCheckout(data: TrackerCheckoutData){
		var fbq = (window as any).fbq;
		var gtag = (window as any).gtag;
		if(fbq){
			fbq('track', 'InitiateCheckout', {
				currency: data.currency, 
				value: data.value,
				num_items: data.items.length,
				content_ids: data.items.map(a=>(a.item_id)),
				eventref: data.transaction_id || ''
			} as PixelCheckoutData);
		}
		if(gtag){
			gtag("event", "begin_checkout", {
				currency: data.currency,
				value: data.value,
				items: data.items,
			} as GtagCheckoutData);
		}
	}

	function viewContent(data: TrackerViewContent){
		var fbq = (window as any).fbq;
		var gtag = (window as any).gtag;
		if(fbq){
			fbq('track', 'ViewContent', {
				content_ids: data.items.map(a=>a.item_id),
				content_name: data.content_name,
				currency: data.currency,
				value: data.value,
				content_category: data.content_category,
				content_type: data.content_type,
				contents: data.items.map(a=>({
					id: a.item_id,
					quantity: a.quantity,
				}))
			} as PixelViewContent);
		}
		if(gtag){
			gtag('event', 'view_item', {
				currency: data.currency,
				value: data.value,
				items: data.items,
			} as GtagViewItem)
		}
	}

	function addToCart(data: TrackerAddCart){
		var fbq = (window as any).fbq;
		var gtag = (window as any).gtag;
		if(fbq){
			fbq('track', 'AddToCart', {
				content_ids: data.items.map(a=>a.item_id),
				content_name: data.content_name,
				content_type: data.content_type || 'product',
				currency: data.currency,
				value: data.value,
				contents: data.items.map(a=>({
					id: a.item_id,
					quantity: a.quantity,
				}))
			} as PixelAddCart);
		}
		if(gtag){
			gtag('event', 'add_to_cart', {
				items: data.items,
				currency: data.currency,
				value: data.value,
			} as GtagAddCart)
		}
	}

	function purchase(data: TrackerPurchase){
		var fbq = (window as any).fbq;
		var gtag = (window as any).gtag;
		if(fbq){
			fbq('track', 'Purchase', {
				content_ids: data.items.map(a=>(a.item_id)),
				content_name: data.content_name,	
				content_type: 'product',
				contents: data.items.map(a=>({
					id: a.item_id,
					quantity: a.quantity,
				})),
				currency: data.currency,
				num_items: data.items.length,
				value: data.value,
			} as PixelPurchase, { eventID: data.transaction_id });
		}
		if(gtag){
			gtag('event', 'purchase', {
				currency: data.currency,
				items: data.items,
				shipping: data.shipping,
				transaction_id: data.transaction_id,
				value: data.value,
				coupon: data.coupon,
			} as GtagPurchase)
		}
	}

	function addPaymentInfo(data: TrackerPaymentInfo){
		var fbq = (window as any).fbq;
		var gtag = (window as any).gtag;
		if(fbq){
			fbq('track', 'AddPaymentInfo', {
				content_ids: data.items.map(a=>a.item_id),
				contents: data.items.map(a=>({
					id: a.item_id,
					quantity: a.quantity,
				})),
				currency: data.currency,
				value: data.value,
				content_category: data.content_category,
			} as PixelPaymentInfo, { eventID: data.transaction_id });
		}
		if(gtag){
			gtag('event', 'add_payment_info', {
				currency: data.currency,
				items: data.items,
				payment_type: data.payment_type,
				value: data.value,
				coupon: data.coupon,
			} as GtagPaymentInfo)
		}
	}

	return (
		<TrackerContext.Provider value={{
			initiateCheckout,
			viewContent,
			addToCart,
			purchase,
			addPaymentInfo,
		}}>
			{props.children}
		</TrackerContext.Provider>
	)
}

export {
	useTracker,
	TrackerProvider,
};