import React, { useEffect, useState } from 'react';
import API from '../APIPublic';
import { Button, Field, Groupper, Header, Input, Message } from 'react-frontier'
import { Dropdown } from 'semantic-ui-react';
import { SetLoading } from '@cocoan/components/Classes';
import { InvoiceForm } from '../ClassesPublic';
import { bindFormChange } from '../UtilPublic';
import { Link, useSearchParams } from 'react-router-dom';
import { useTitle } from '../HooksPublic';
import Validator from '@cocoan/components/Validator';


const MEXICO_STATES = [
	'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 
	'Coahuila', 'Colima', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Durango', 'Guanajuato', 
	'Guerrero', 'Hidalgo', 'Jalisco', 'México', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 
	'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 
	'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'
];

var InvoiceCreate = ()=>{
	var { setTitle } = useTitle();
	var [params] = useSearchParams();
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [invoiceResponse, setInvoiceResponse] = useState<{ invoice_url: string, created: boolean }>(null);
	var [form, setForm] = useState<InvoiceForm>({
		order_hash: '',
		email: '',
		legal_name: '',
		rfc: '',
		regimen: '',
		use_cfdi: '',
		address: {
			street: '',
			exterior_number: '',
			interior_number: '',
			neighborhood: '',
			zipcode: '',
			city: '',
			state: '',
			country: 'México'
		}
	});

	useEffect(()=>{
		if(params && params.get('o')){
			setForm({
				...form,
				order_hash: params.get('o'),
			});
		}
		setTitle('Facturación')
	}, [params]);

	var createInvoice = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({
			...form,
			...form.address
		}, {
			order_hash: [
				{ rule: /[0-9a-z]{4}/gi, prompt: 'El folio de compra no es válido' }
			],
			email: [
				{ rule: 'email', prompt: 'El correo electrónico no es válido' }
			],
			legal_name: [
				{ rule: 'minLength', params: [3], prompt: 'La razón social no es válida' },
			],
			rfc: [
				{ rule: 'minLength', params: [12], prompt: 'El RFC no es válido' },
			],
			regimen: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el régimen fiscal' }
			],
			use_cfdi: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el uso de CFDI' }
			],
			street: [
				{ rule: 'minLength', params: [3], label: 'Calle' },
			],
			exterior_number: [
				{ rule: 'empty', label: 'Número exterior' },
				{ rule: 'maxLength', params: [16], label: 'Número exterior' },
			],
			interior_number: [
				{ rule: 'maxLength', params: [16], label: 'Número interior' },
			],
			neighborhood: [
				{ rule: 'minLength', params: [3], label: 'Colonia' },
				{ rule: 'maxLength', params: [128], label: 'Colonia' },
			],
			zipcode: [
				{ rule: 'minLength', params: [4], label: 'Código postal' },
				{ rule: 'maxLength', params: [16], label: 'Código postal' },
			],
			city: [
				{ rule: 'minLength', params: [3], label: 'Ciudad' },
				{ rule: 'maxLength', params: [128], label: 'Ciudad' },
			],
			state: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el estado' },
				{ rule: 'maxLength', params: [128], label: 'Estado' },
			]
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.createOrderInvoice(form, form.address).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			setInvoiceResponse(res.data);
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado creando la factura (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onFormChange = bindFormChange(form, setForm);
	var onAddressChange = bindFormChange(form.address, v=>{
		setForm({
			...form,
			address: v
		});
	})
	
	return <div className="ar create-invoice screen">
		<Header 
			text='Facturar' 
			style={{
				margin: '15px auto 20px auto',
				maxWidth: 600,
				padding: '0 10px'
			}}
			subheaderStyle={{
				fontSize: 16,
				lineHeight: '18px' 
			}}
		/>
		<Groupper title='Información de compra' width={500} titleCentered actions={(
			<Button text='Facturar' color='black' onClick={createInvoice} disabled={!!invoiceResponse} />
		)}>
			<Input label='Folio de compra' value={form.order_hash} onChange={onFormChange('order_hash')} />
			<Input label='Correo electrónico' value={form.email} onChange={onFormChange('email')} />
			<Groupper.Divider text='Información fiscal' centered style={{ marginTop: 20 }} />
			<Input label='Razón social' value={form.legal_name} onChange={onFormChange('legal_name')} />
			<Input label='RFC' value={form.rfc} onChange={onFormChange('rfc')} />
			<Field label='Régimen fiscal'>
				<Dropdown
					search
					selection
					selectOnBlur={false}
					placeholder='Régimen fiscal'
					value={form.regimen}
					onChange={onFormChange('regimen', true)}
					options={[
						{ value: "601", text: "General de Ley Personas Morales", description: "(601)" },
						{ value: "603", text: "Personas Morales con Fines no Lucrativos", description: "(603)" },
						{ value: "605", text: "Sueldos y Salarios e Ingresos Asimilados a Salarios", description: "(605)" },
						{ value: "606", text: "Arrendamiento", description: "(606)" },
						{ value: "607", text: "Régimen de Enajenación o Adquisición de Bienes", description: "(607)" },
						{ value: "608", text: "Demás ingresos", description: "(608)" },
						{ value: "609", text: "Consolidación", description: "(609)" },
						{ value: "610", text: "Residentes en el Extranjero sin Establecimiento Permanente en México", description: "(610)" },
						{ value: "611", text: "Ingresos por Dividendos (socios y accionistas)", description: "(611)" },
						{ value: "612", text: "Personas Físicas con Actividades Empresariales y Profesionales", description: "(612)" },
						{ value: "614", text: "Ingresos por intereses", description: "(614)" },
						{ value: "615", text: "Régimen de los ingresos por obtención de premios", description: "(615)" },
						{ value: "616", text: "Sin obligaciones fiscales", description: "(616)" },
						{ value: "620", text: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", description: "(620)" },
						{ value: "621", text: "Incorporación Fiscal", description: "(621)" },
						{ value: "622", text: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", description: "(622)" },
						{ value: "623", text: "Opcional para Grupos de Sociedades", description: "(623)" },
						{ value: "624", text: "Coordinados", description: "(624)" },
						{ value: "625", text: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas", description: "(625)" },
						{ value: "626", text: "Régimen Simplificado de Confianza", description: "(626)" },
						{ value: "628", text: "Hidrocarburos", description: "(628)" },
						{ value: "629", text: "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales", description: "(629)" },
						{ value: "630", text: "Enajenación de acciones en bolsa de valores", description: "(630)" },
					]}
				/>
			</Field>
			<Field label='Uso de CFDI'>
				<Dropdown
					search
					selection
					selectOnBlur={false}
					placeholder='Uso de CFDI'
					value={form.use_cfdi}
					onChange={onFormChange('use_cfdi', true)}
					options={[
						{ value: "G01", text: "Adquisición de mercancías", description: "(G01)" },
						{ value: "G02", text: "Devoluciones, descuentos o bonificaciones", description: "(G02)" },
						{ value: "G03", text: "Gastos en general", description: "(G03)" },
						{ value: "I01", text: "Construcciones", description: "(I01)" },
						{ value: "I02", text: "Mobiliario y equipo de oficina por inversiones", description: "(I02)" },
						{ value: "I03", text: "Equipo de transporte", description: "(I03)" },
						{ value: "I04", text: "Equipo de cómputo y accesorios", description: "(I04)" },
						{ value: "I05", text: "Dados, troqueles, moldes, matrices y herramental", description: "(I05)" },
						{ value: "I06", text: "Comunicaciones telefónicas", description: "(I06)" },
						{ value: "I07", text: "Comunicaciones satelitales", description: "(I07)" },
						{ value: "I08", text: "Otra maquinaria y equipo", description: "(I08)" },
						{ value: "D01", text: "Honorarios médicos, dentales y gastos hospitalarios.", description: "(D01)" },
						{ value: "D02", text: "Gastos médicos por incapacidad o discapacidad", description: "(D02)" },
						{ value: "D03", text: "Gastos funerales", description: "(D03)" },
						{ value: "D04", text: "Donativos", description: "(D04)" },
						{ value: "D05", text: "Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)", description: "(D05)" },
						{ value: "D06", text: "Aportaciones voluntarias al SAR.", description: "(D06)" },
						{ value: "D07", text: "Primas por seguros de gastos médicos.", description: "(D07)" },
						{ value: "D08", text: "Gastos de transportación escolar obligatoria.", description: "(D08)" },
						{ value: "D09", text: "Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones.", description: "(D09)" },
						{ value: "D10", text: "Pagos por servicios educativos (colegiaturas)", description: "(D10)" },
						{ value: "CP01", text: "Pagos", description: "(CP01)" },
						{ value: "CN01", text: "Nómina", description: "(CN01)" },
						{ value: "S01", text: "Sin Efectos Fiscales", description: "(S01)" },
					]}
				/>
			</Field>
			<Groupper.Divider text='Dirección fiscal' centered style={{ marginTop: 20 }} />
			<Input label='Calle' value={form.address.street} onChange={onAddressChange('street')} />
			<Field amount={2}>
				<Input label='Número exterior' value={form.address.exterior_number} onChange={onAddressChange('exterior_number')} />
				<Input label='Número interior' value={form.address.interior_number} onChange={onAddressChange('interior_number')} />
			</Field>
			<Field amount={2}>
				<Input label='Colonia' value={form.address.neighborhood} onChange={onAddressChange('neighborhood')} />
				<Input label='Código postal' value={form.address.zipcode} onChange={onAddressChange('zipcode')} />
			</Field>
			<Input label='Ciudad' value={form.address.city} onChange={onAddressChange('city')} />
			<Field label='Estado'>
				<Dropdown
					search
					selection
					selectOnBlur={false}
					placeholder='Estado'
					value={form.address.state}
					onChange={onAddressChange('state', true)}
					options={MEXICO_STATES.map(a=>({
						value: a, text: a
					}))}
				/>
			</Field>
			<Message type='error' list={errorPrompts} />
			{!!invoiceResponse && (
				<Message type='info' centered>
					<div className="header">Factura creada</div>
					{invoiceResponse.created ? (
						'Hemos creado tu factura con la información proporcionada. Hemos enviado un correo electrónico con la factura a la dirección de correo de la orden. Adicionalmente'
					) : (
						'Ya existía una factura creada para esta orden'
					)}
					, podrás descargarla siguiendo el siguiente enlace
					<Button size='big' color='blue' text='Descargar factura' iconName='file-zipper' as={Link} to={invoiceResponse.invoice_url} style={{ marginTop: 15, width: 250 }} />
				</Message>
			)}
		</Groupper>
	</div>
}

export default InvoiceCreate;