import React, { useEffect, useState } from 'react';
import { Header } from 'react-frontier';
import { useNavigate, useParams } from 'react-router-dom';
import { NotFound } from '@cocoan/components';
import API from '../APIPublic';

var CheckoutLink = ()=>{
	var { token } = useParams();
	var [loadError, setLoadError] = useState<string>(null);
	var [notFound, setNotFound] = useState<boolean>(false);
	var navigate = useNavigate();
	var token_valid = (/(^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$)/gi).test(token);

	useEffect(()=>{
		if(!token_valid) return;
		setLoadError(null);
		setNotFound(false);
		API.getCheckoutLink(token).then(res=>{
			if(res.error) return setLoadError(res.message);
			if(!res.data || !res.data.order_hash) return setNotFound(true);
			navigate(`/checkout/${res.data.order_hash}`, {
				replace: true
			});
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando el pago de la orden.');
		});
	}, [token]);

	if(loadError) return <Header iconName='frown-open' text='Error' subtext={loadError} />
	if(!token_valid || notFound) return <NotFound />
	
	return <div>
		<Header text='Cargando orden' loading />
	</div>
}

export default CheckoutLink;