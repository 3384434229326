import React, { useEffect, useRef, useState } from 'react';
import { Button, Field, Groupper, Header, Items, Message, Placeholder, Input } from 'react-frontier';
import { useCart, useTitle, useWebdata } from '../HooksPublic';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CartItem } from '@cocoan/components';
import { CDN_URL } from '../ConfigPublic';
import { addCommas, bindChange, bindFormChange } from '../UtilPublic';
import { Breadcrumbs, PayfrontForm } from '../components';
import { Checkbox } from 'semantic-ui-react';
import { APIResponse, ShippingMethod, ShippingType } from '../ClassesPublic';
import { CheckoutOrder, ProcessorProvider } from '@cocoan/components/Classes';
import { PayfrontFormElement } from '../components/PayfrontForm';
import { useTracker } from '@cocoan/components/TrackerHook';
import Validator from '@cocoan/components/Validator';
import API from '../APIPublic';
import classNames from 'classnames';

import '../style/checkout.scss'
import moment from 'moment';

enum CheckoutStage{
	INFORMATION = 1,
	SHIPPING = 2,
	PAYMENT = 3,
	COMPLETE = 4,
}

const MOBILE_BREAKPOINT = 810;

var Checkout = ()=>{
	var { cart, loading: cartLoading, total: cartTotal, serializedCart, clearCart, addToCart } = useCart();
	var [sending, setSending] = useState<boolean>(false);
	var [contactEmail, setContactEmail] = useState<string>(null);
	var [newsletter, setNewsletter] = useState<boolean>(true);
	var [stage, setStage] = useState<CheckoutStage>(CheckoutStage.INFORMATION);
	var [shippingMethod, setShippingMethod] = useState<number>(null);
	var [paymentMethod, setPaymentMethod] = useState<number>(null);
	var [shipMethods, setShipMethods] = useState<ShippingMethod[]>(null);
	var [checkoutOrder, setCheckoutOrder] = useState<CheckoutOrder>(null);
	var [lockedDate, setLockedDate] = useState<boolean>(false);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [cartShown, setCartShown] = useState<boolean>(false);
	var [mobile, setMobile] = useState<boolean>(false);
	var [shippingClosedDays, setShippingClosedDays] = useState<number[]>(null);
	var { setTitle } = useTitle();
	var Tracker = useTracker();
	var webdata = useWebdata();
	var Payfront = useRef<PayfrontFormElement>(null);
	var navigate = useNavigate();
	var params = useParams();
	const HAS_ORDER = params['*'] && params['*'].length>0;
	
	useEffect(()=>{
		if(!HAS_ORDER && !cartLoading && cart.length==0){
			return navigate('/shop');
		}
	}, [params, cart, cartLoading]);

	useEffect(()=>{
		setTitle('Checkout');
		if(HAS_ORDER){
			if(!checkoutOrder) getCheckoutOrder(params['*']);
			if(!checkoutOrder || (!checkoutOrder.owner_name || checkoutOrder.owner_name.length==0)){
				goToStage(CheckoutStage.SHIPPING);
			}
		}else{
			goToStage(CheckoutStage.INFORMATION);
		}
	}, [HAS_ORDER]);

	useEffect(()=>{
		var onResize = ()=>{
			if(!mobile && window.innerWidth<=MOBILE_BREAKPOINT){
				setMobile(true);
			}else if(mobile && window.innerWidth>MOBILE_BREAKPOINT){
				setMobile(false);
			}
		}
		window.addEventListener('resize', onResize);
		setMobile(window.innerWidth<=MOBILE_BREAKPOINT)
		return ()=>{
			window.removeEventListener('resize', onResize);
		}
	}, [mobile])

	var getCheckoutOrder = (order_hash: string)=>{
		setLoadError(null);
		API.getCheckoutOrder(order_hash).then(res=>{
			if(res.error){
				return setLoadError(res.message);
			}
			if(res.data.paid || res.data.executed){
				return navigate(`/order/${order_hash}?p=1`);
			}
			setCheckoutOrder(res.data);
			if(!res.data.shipping_location && res.data.shipping_date){
				setLockedDate(true);
			}
			getShippingClosedDates(res.data.shipping_method_type);
			if (res.data.owner_name && res.data.owner_name.length > 0) {
				goToStage(res.data.shipping_method_type === 1 && res.data.shipping_location == null ? CheckoutStage.SHIPPING : CheckoutStage.PAYMENT);
			}
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando la información de la orden. (LCL-1)');
		});
	}

	var getShippingMethods = ()=>{
		if(shipMethods) return;
		API.getShippingMethods().then(res=>{
			if(res.error) return setLoadError(res.message);
			setShipMethods(res.data);
		}).catch(err=>{
			return setLoadError('Hubo un error inesperado cargando las formas de envío (LCL-1)');
		})
	}

	var getShippingClosedDates = (shipping_type: number)=>{
		if(shippingClosedDays) return;
		API.getShippingClosedDates(shipping_type).then(res=>{
			if(res.error) return; // Maybe display a message?
			setShippingClosedDays(res.data);
		}).catch(err=>{
			// Maybe display a message?
		});
	}

	var getTotal = ()=>{
		if(checkoutOrder){
			if(stage===CheckoutStage.SHIPPING && checkoutOrder.shipping_method_local){
				return checkoutOrder.total - (checkoutOrder.shipping_cost || 0)
			}
			return checkoutOrder.total;
		}
		var selected_shipping_method = shipMethods && shippingMethod ? shipMethods.find(a=>a.method_id==shippingMethod) : null;
		var total = cartTotal;
		
		if(selected_shipping_method && !selected_shipping_method.local && total < webdata.variables.shipping_limit){
			total += selected_shipping_method.cost;
		}

		return total;
	}

	var createOrder = ()=>{
		setSending(true);
		Tracker.initiateCheckout({
			currency: 'MXN',
			items: cart.map(a=>({
				item_id: a.product_id.toString(),
				quantity: a.quantity,
				item_name: a.product_name,
				price: a.price,
				// item_variant: a.presentation_id,
			})),
			value: cart.reduce((a,b)=>a+(b.price*b.quantity), 0),
		})
		setErrorPrompts(null);
		API.createOrder(contactEmail, shippingMethod, serializedCart, newsletter).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(!res.data.order_hash){
				return setErrorPrompts(['Hubo un error inesperado enviando la información (LCL-INCT-1)']);
			}else{
				navigate(`/checkout/${res.data.order_hash}`, { replace: false });
			}
			goToStage(CheckoutStage.SHIPPING);
			setCheckoutOrder(res.data);
			getShippingClosedDates(res.data.shipping_method_type);
			clearCart();
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado enviando la información (LCL-INCT-1)']);
		}).finally(()=>{
			setSending(false);
		});
	}

	var goToStage = (stage: CheckoutStage)=>{
		setStage(stage);
		if(stage===CheckoutStage.INFORMATION){
			if(!shipMethods){
				getShippingMethods();
			}
		}else if(stage===CheckoutStage.SHIPPING){
			if(checkoutOrder){
				getShippingClosedDates(checkoutOrder.shipping_method_type);
			}
		}
	}

	var sendShipping = ()=>{
		if(!checkoutOrder) return;
		var { valid, prompts } = Validator({
			owner_name: checkoutOrder.owner_name,
			owner_last_name: checkoutOrder.owner_name,
			shipping_date: checkoutOrder.shipping_date,
			owner_phone: checkoutOrder.owner_phone,
			gift_name: checkoutOrder.gift_name,
			gift_last_name: checkoutOrder.gift_last_name,
			gift_phone: checkoutOrder.gift_phone,
			gift_message: checkoutOrder.gift_message,
			...checkoutOrder.location
		}, {
			owner_name: [{
				rule: 'minLength', params: [3], label: 'Nombre comprador',
			}, {
				rule: 'maxLength', params: [64], label: 'Nombre comprador',
			}],
			owner_last_name: [{
				rule: 'minLength', params: [3], label: 'Apellido comprador',
			}, {
				rule: 'maxLength', params: [64], label: 'Apellido comprador',
			}],
			owner_phone: [{
				rule: 'phone', prompt: 'El teléfono de comprador no es válido'
			}],
			shipping_date: [{
				rule: 'empty', prompt: 'Favor de seleccionar la fecha de entrega',
			}],
			...(
				checkoutOrder.shipping_method_type===ShippingType.SHIPPING ? {
					street: [{ rule: 'minLength', params: [3], label: 'Calle' }],
					exterior_number: [{ rule: 'empty', label: 'Número exterior' }],
					neighborhood: [{ rule: 'minLength', params: [3], label: 'Colonia' }],
					city: [{ rule: 'minLength', params: [3], label: 'Ciudad' }],
					state: [{ rule: 'minLength', params: [3], label: 'Estado' }],
					zipcode: [{ rule: 'minLength', params: [3], label: 'Código postal' }]
				} : {}
			),
			...(checkoutOrder.gift ? {
				gift_name: [{ rule: 'empty', label: 'Nombre de quien recibe' }],
				gift_last_name: [{ rule: 'empty', label: 'Apellido de quien recibe' }],
				gift_message: [{ rule: 'maxLength', params: [256], label: 'Mensaje de regalo' }]
			} : {})
		});

		setErrorPrompts(prompts);
		if(!valid) return;

		setSending(true);
		API.sendCheckoutShipping(checkoutOrder.order_hash, {
			owner_name: checkoutOrder.owner_name,
			owner_last_name: checkoutOrder.owner_last_name,
			owner_phone: checkoutOrder.owner_phone,
			shipping_date: checkoutOrder.shipping_date,
			shipping_method: checkoutOrder.shipping_method,
			gift: checkoutOrder.gift ? {
				gift_name: checkoutOrder.gift_name,
				gift_last_name: checkoutOrder.gift_last_name,
				gift_message: checkoutOrder.gift_message,
				gift_phone: checkoutOrder.gift_phone,
			} : null,
			location: {
				street: checkoutOrder.location.street,
				exterior_number: checkoutOrder.location.exterior_number,
				interior_number: checkoutOrder.location.interior_number,
				neighborhood: checkoutOrder.location.neighborhood,
				city: checkoutOrder.location.city,
				state: checkoutOrder.location.state,
				zipcode: checkoutOrder.location.zipcode,
				comments: checkoutOrder.location.comments
			}
		}).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data.paid){
				return navigate(`/order/${checkoutOrder.order_hash}?p=1`);
			}
			setCheckoutOrder({
				...checkoutOrder,
				shipping_cost: res.data.shipping_cost,
				total: res.data.total,
				paid: res.data.paid,
			});
			setStage(CheckoutStage.PAYMENT);
		}).finally(()=>{
			setSending(false);
		})
	}

	var createPayfrontPayment = async ()=>{
		if(!Payfront || !Payfront.current) return;
		setErrorPrompts(null);
		setSending(true);
		
		try{
			var pfres = await Payfront.current?.getToken(getTotal());
			if(pfres.error){
				setSending(false);
				return;
			}
		}catch(e){
			setSending(false);
			return;
		}

		try{
			var res : APIResponse<{ redirect: string }>;
			if(pfres.data.type===ProcessorProvider.PAYFRONT){
				res = await API.sendCardPayment(checkoutOrder.order_hash, pfres.data.token, pfres.data.digits, pfres.data.bin);
			}else{
				res = await API.sendCardPaymentOpenpay(checkoutOrder.order_hash, pfres.data.token, pfres.data.device, pfres.data.digits, pfres.data.bin);
			}
			
			if(res.error) return setErrorPrompts([res.message]);
			if(!res.data || !res.data.redirect){
				return setErrorPrompts(['Hubo un error realizando el pago. No se hizo ningún cobro a la tarjeta bancaria. (LCL-2)'])
			}
			window.location.href = res.data.redirect;
		}catch(e){
			return setErrorPrompts(['Hubo un error realizando el pago. No se hizo ningún cobro a la tarjeta bancaria. (LCL-1)'])
		}finally{
			setSending(false);
		}
	}

	var createOtherPayment = (method_id: number)=>{
		setSending(true);
		API.sendCreatePayment(checkoutOrder.order_hash, method_id).then(res=>{
			if(res.error){
				return setErrorPrompts([res.message]);
			}
			return navigate(`/order/${checkoutOrder.order_hash}?p=1`);
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error creando el pago. (LCL-1)'])
		}).finally(()=>{
			setSending(false);
		})
	}

	var nextStage = ()=>{
		setErrorPrompts(null);
		if(stage===CheckoutStage.PAYMENT){
			if(checkoutOrder){
				Tracker.addPaymentInfo({
					currency: 'MXN',
					items: checkoutOrder.items.map(a=>({
						item_id: a.product_id.toString(),
						item_name: a.product_name,
						price: a.cost_single,
						quantity: a.quantity,
						// item_variant: a.presentation_id
					})),
					payment_type: paymentMethod.toString(),
					transaction_id: `CCN-${checkoutOrder.order_id}`,
					value: checkoutOrder.total,
				})
			}
			if(paymentMethod===4){
				createPayfrontPayment();
			}else if(paymentMethod===10 || paymentMethod===11){
				createOtherPayment(paymentMethod);
			}
		}else if(stage===CheckoutStage.INFORMATION){
			createOrder();
		}else if(stage===CheckoutStage.SHIPPING){
			sendShipping();
		}
	}

	var ContinueButtons = ()=>{
		var active = true, button_text = 'Continuar';
		if(HAS_ORDER && !checkoutOrder) return null;
		if(stage===CheckoutStage.INFORMATION){
			active = Validator.validate('email', contactEmail) && !!shippingMethod;
			button_text = 'Continuar a entrega';
		}else if(stage===CheckoutStage.SHIPPING){
			button_text = 'Continuar a pago';
			active = !!checkoutOrder;
		}else if(stage===CheckoutStage.PAYMENT){
			active = !!paymentMethod;
			button_text = `Pagar $${addCommas(getTotal(), true, true)}`;
		}
		return <>
			<div className={classNames("next-buttons", {
				single: !!checkoutOrder
			})}>
				{!checkoutOrder && (
					<Button text='Carrito' iconName='arrow-left' basic color='black' as={Link} to="/shop" />
				)}
				<Button text={button_text} color='black' onClick={nextStage} loading={sending} disabled={!active} />
			</div>
			<div style={{ height: 50 }} />
		</>
	}

	var selected_shipping_method = shipMethods && shippingMethod ? shipMethods.find(a=>a.method_id==shippingMethod) : null;
	var shown_cart = checkoutOrder ? checkoutOrder.items.map(a=>({
		product_id: a.product_id,
		product_name: a.product_name,
		price: a.cost_single,
		quantity: a.quantity,
		size: a.size,
	})) : cart;
	var order_items_total = !checkoutOrder ? cartTotal : checkoutOrder.items.reduce((a,b)=>a+b.cost, 0);
	var onCheckoutForm = bindFormChange(checkoutOrder, setCheckoutOrder);
	var onLocationForm = checkoutOrder ? bindFormChange(checkoutOrder.location, (v=>{
		setCheckoutOrder({
			...checkoutOrder,
			location: v
		});
	})) : null;

	var now = moment();
	var shipping_min_date = moment().startOf('day');

	if(checkoutOrder?.shipping_method_type===ShippingType.PICKUP && now.get('hour')>=webdata?.variables.pickup_end){
		shipping_min_date.add(1, 'day');
	}else if(checkoutOrder?.shipping_method_type===ShippingType.SHIPPING && now.get('hour')>=webdata?.variables.shipping_end){
		shipping_min_date.add(1, 'day');
	}

	var now_start = moment().subtract(7, 'day');
	var shipping_max_date = moment().add(6, 'month');
	now_start = now_start.subtract(now.day(), 'day')
	var shipping_sundays : number[] = [];
	while(now_start.isBefore(shipping_max_date)){
		shipping_sundays.push(now_start.clone().unix());
		now_start = now_start.add(7, 'day');
	}

	var discounted_shipping = webdata && getTotal()>=webdata.variables.shipping_limit;
	var free_shipping = discounted_shipping && webdata.variables.shipping_cost<=0;

	return <div className="co checkout-screen">
		<div className="contents">
			<div className="container">
				<Breadcrumbs
					activeStyle={{ fontWeight: 'bold' }}
					active={stage}
					beforeStyle={{ color: 'black' }}
					items={['Carrito', 'Contacto', 'Entrega', 'Pago']}
				/>
				{!!checkoutOrder ? (
					<div className="co summary">
						{stage===CheckoutStage.PAYMENT && checkoutOrder.owner_name.length>0 && (
							<div className="item">
								<div className="meta">Enviar a</div>
								<div className="text">
									{!!checkoutOrder.gift && checkoutOrder.gift_name.length>=3 && (
										<i className="gift icon"></i>
									)}
									{(!!checkoutOrder.gift && checkoutOrder.gift_name.length>=3) ? `${checkoutOrder.gift_name} ${checkoutOrder.gift_last_name || ''}` : `${checkoutOrder.owner_name} ${checkoutOrder.owner_last_name || ''}`}
								</div>
							</div>
						)}
						<div className="item">
							<div className="meta">Correo electrónico</div>
							<div className="text">{checkoutOrder.owner_email}</div>
						</div>
						<div className="item">
							<div className="meta">Tipo de entrega</div>
							<div className="text">{checkoutOrder.shipping_method_name}</div>
							{(!!checkoutOrder.shipping_date) && (
								<div className="meta">{moment.unix(checkoutOrder.shipping_date).format('DD/MMM/YYYY')}</div>
							)}
						</div>
					</div>
				) : (!loadError && stage!==CheckoutStage.INFORMATION) && (
					<Placeholder height={120} style={{ display: 'block', borderRadius: 12, width: '100%', marginBottom: 15 }} />
				)}
				{stage===null || stage===CheckoutStage.INFORMATION ? <>
					<Input label='Correo electrónico' value={contactEmail} onChange={setContactEmail} />
					<Checkbox toggle label={'Deseo recibir promociones y noticias de COCOAN'} style={{ marginTop: 5 }} checked={!!newsletter} onChange={bindChange(setNewsletter, true)} />
					<Header size='small' text='Tipo de entrega' centered={false} style={{ marginTop: 40 }} />
					{loadError && !shipMethods ? (
						<Header text='Error' subtext={loadError} />
					) : shipMethods && shipMethods.length === 0 ? (
						<Message text='Las entregas se encuentran deshabilitadas por el momento.' type='info' />
					) : (
						<Items
							selectable
							single
							selected={shippingMethod}
							onSelected={a=>{
								if(!shipMethods) return;
								if(a.min_total>order_items_total) return
								setShippingMethod(a.method_id)
							}}
							data={shipMethods || (new Array(3).fill(0).map((a,i)=>({ method_id: i+1023, name: '', cost: 0 })) as ShippingMethod[])}
							valueExtractor={a=>a.method_id}
							render={(a, active)=>{
								if(!shipMethods){
									return <div className='header'>
										<i className="circle outline icon"></i>
										<div className="left">
											<Placeholder style={{ backgroundColor: '#E9E9E9' }} width={187} />
										</div>
										<div className="right">
											<Placeholder style={{ backgroundColor: '#E9E9E9' }} width={30} minWidth={60} />
										</div>
									</div>
								}else{
									return <>
										<div className={classNames('header', {
											disabled: a.min_total>order_items_total
										})}>
											<i className={classNames('circle icon', {
												outline: !active,
												check: active,
											})}></i>
											<div className="left">
												{a.name}
												{a.min_total>order_items_total && (
													<div className="meta">
														Mínimo de compra: ${addCommas(a.min_total)}
													</div>
												)}
											</div>
											<div className={classNames("cost", {
												free: a.cost<=0 || !!a.local || free_shipping,
											})}>
												{(a.local && !free_shipping) ? (
													'Variado'
												) : (
													(a.cost<=0 || free_shipping) ? (
														'Gratis'
													) : (
														`$${addCommas(discounted_shipping ? Math.min(webdata.variables.shipping_cost, a.cost) : a.cost)}`
													)
												)}
											</div>
										</div>
										{active && (!!a.description && a.description.length>0) && (
											<div className="content">{a.description}</div>
										)}
									</>
								}
							}}
						/>
					)}
					{webdata && (
						!webdata.variables.pickup_enabled && webdata.variables.shipping_enabled ? 
							<Message text='Los pickups se encuentran deshabilitados por el momento.' type='info' style={{ marginTop: 15 }} /> 
							: !webdata.variables.shipping_enabled && webdata.variables.pickup_enabled ? 
								<Message text='Los envíos se encuentran deshabilitados por el momento.' type='info' style={{ marginTop: 15 }} /> 
								: null
					)}
				</> : stage===CheckoutStage.SHIPPING ? (
					loadError ? (
						<Header text='Error' subtext={loadError} />
					) : checkoutOrder ? <>
						<Field amount={2}>
							<Input label='Nombre' value={checkoutOrder.owner_name} onChange={onCheckoutForm('owner_name')} style={{ marginTop: 10 }} />
							<Input label='Apellido' value={checkoutOrder.owner_last_name} onChange={onCheckoutForm('owner_last_name')} style={{ marginTop: 10 }} />
						</Field>
						<Input label={`Teléfono ${checkoutOrder?.gift ? 'comprador' : ''}`} value={checkoutOrder.owner_phone} onChange={onCheckoutForm('owner_phone')} style={{ marginBottom: 15 }} />
						<Checkbox toggle label="Esta compra es un regalo" onChange={onCheckoutForm('gift', true)} checked={!!checkoutOrder.gift} />

						{!!checkoutOrder.gift && (
							<Groupper title='Opciones de regalo' style={{ marginTop: 15 }} className='compact'>
								<Field amount={2}>
									<Input label='Nombre de quien recibe' className='normal' value={checkoutOrder.gift_name} onChange={onCheckoutForm('gift_name')} />
									<Input label='Apellido de quien recibe' className='normal' value={checkoutOrder.gift_last_name} onChange={onCheckoutForm('gift_last_name')} />
								</Field>
								<Input label='Mensaje de regalo' textarea value={checkoutOrder.gift_message} onChange={onCheckoutForm('gift_message')} />
								{checkoutOrder.shipping_method_type!==ShippingType.PICKUP && (
									<Input label='Teléfono de quien recibe' className='normal' comment={'Usarémos este teléfono por si la persona que recibe el regalo no está disponible.'} value={checkoutOrder.gift_phone} onChange={onCheckoutForm('gift_phone')} />
								)}
							</Groupper>
						)}
						<Header text='Información de entrega' size='small' />
						<Input
							readonly
							className={!shippingClosedDays ? 'loading' : null}
							label='Fecha de entrega'
							onChange={onCheckoutForm('shipping_date')} 
							comment={lockedDate ? 'La fecha de entrega ha sido preseleccionada por el equipo de COCOAN' : null}
							value={!lockedDate ? checkoutOrder.shipping_date : moment.unix(checkoutOrder.shipping_date).format('DD/MMM/YYYY')}
							calendar={(!lockedDate && shippingClosedDays && !checkoutOrder.seller_id) ? {
								date: checkoutOrder.shipping_date,
								initialDate: shipping_min_date.unix(),
								mode: 'date',
								format: 'DD/MMM/YYYY',
								minDate: shipping_min_date.unix(),
								maxDate: shipping_max_date.unix(),
								disabledDays: [...(shippingClosedDays || []), ...shipping_sundays],
							} : null}
						/>
						{checkoutOrder.shipping_method_type===ShippingType.PICKUP ? (
							null
						) : checkoutOrder.shipping_method_type===ShippingType.SHIPPING ? <>
							<Field amount={2}>
								<Input label='Calle' value={checkoutOrder.location?.street} onChange={onLocationForm('street')} />
								<Field amount={2} style={{ marginBottom: 0 }}>
									<Input label='Número exterior' value={checkoutOrder.location?.exterior_number} onChange={onLocationForm('exterior_number')} style={{ marginBottom: 0 }} />
									<Input label='Número interior' value={checkoutOrder.location?.interior_number} onChange={onLocationForm('interior_number')} style={{ marginBottom: 0 }} />
								</Field>
							</Field>
							<Field amount={2}>
								<Input label='Colonia' value={checkoutOrder.location?.neighborhood} onChange={onLocationForm('neighborhood')} />
								<Input label='Ciudad' value={checkoutOrder.location?.city} onChange={onLocationForm('city')} />
							</Field>
							<Field amount={2}>
								<Input label='Estado' value={checkoutOrder.location?.state} onChange={onLocationForm('state')} />
								<Input label='Código postal' value={checkoutOrder.location?.zipcode} onChange={onLocationForm('zipcode')} />
							</Field>
							<Input label='Referencia' textarea comment='Referencia de la dirección de envío para nuestro repartidor' value={checkoutOrder.location?.comments} onChange={onLocationForm('comments')} />
						</> : (
							<Header text="Error" subtext='Tipo de envío inválido' />
						)}
					</> : <>
						<Field amount={2}>
							<Field>
								<Placeholder style={{ display: 'block' }} />
								<Placeholder style={{ display: 'block', width: '100%', marginTop: 8, borderRadius: 8 }} height={40} />
							</Field>
							<Field>
								<Placeholder style={{ display: 'block' }} />
								<Placeholder style={{ display: 'block', width: '100%', marginTop: 8, borderRadius: 8 }} height={40} />
							</Field>
						</Field>
						<Placeholder style={{ display: 'block' }} />
						<Placeholder style={{ display: 'block', width: '100%', marginTop: 8, borderRadius: 8 }} height={40} />
						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
							<Placeholder height={40} width={120} />
						</div>
					</>
				) : stage===CheckoutStage.PAYMENT ? <>
					<Header size='small' text='Forma de pago' centered={false} />
					<Items
						data={[
							{ 
								method_id: 4, 
								method_name: 'Tarjeta Bancaria',
								icon: (
									<i className="credit card icon" style={{ margin: 0 }}></i>
								),
								contents: (
									<PayfrontForm
										ref={Payfront}
										sandbox={API.IS_SANDBOX}
										openpay={{
											merchantId: API.OPENPAY_CONFIG.merchant_id,
											apiKey: API.OPENPAY_CONFIG.api_key,
										}}
										payfront={{
											clientId: API.PAYFRONT_CONFIG.client_id,
											publicKey: API.PAYFRONT_CONFIG.public_key
										}}
									/>
								)
							},
							// { 
							// 	method_id: 10, 
							// 	method_name: 'Oxxo Pay',
							// 	contents: null,
							// 	icon: (
							// 		<img src='/badges/oxxo.webp' style={{ height: 18 }} />
							// 	)
							// },
							// { 
							// 	method_id: 11, 
							// 	method_name: 'Transferencia Bancaria (SPEI)',
							// 	contents: (
							// 		<Message centered type='info'>
							// 			Este método de pago puede tardar hasta 2 horas en reflejarse una vez hecha la transferencia. 
							// 			<br />
							// 			Antes de contactarnos, <b>favor de asegurarse de que se deposite a la cuenta indicada y se le haya hecho el cargo a su cuenta bancaria despues de realizar la transferencia.</b>
							// 		</Message>
							// 	),
							// 	icon: (
							// 		<i className="corner dollar big icon" style={{ margin: 0 }}></i>
							// 	),
							// }
						]}
						selectable
						selected={paymentMethod}
						onToggle={setPaymentMethod}
						valueExtractor={a=>a.method_id}
						single
						render={(a, active)=>{
							return <>
								<div className="header">
									<i className={classNames('circle icon', {
										outline: !active,
										check: active,
									})}></i>
									<div className="left">
										{a.method_name}
									</div>
									{a.icon}
								</div>
								{active && a.contents && (
									<div className="content">
										{a.contents}
									</div>
								)}
							</>
						}}
					/>
				</> : null}
				<Message list={errorPrompts} type='error' />
				{!loadError && (
					<ContinueButtons />
				)}
			</div>
		</div>
		<div className="cart">
			{loadError && HAS_ORDER ? (
				<Header text='Error' subtext={loadError} />
			) : (cartLoading && !HAS_ORDER) || (HAS_ORDER && !checkoutOrder) ? (
				<Header loading text='Cargando carrito' />
			) : <>
				<div className="head" onClick={()=>setCartShown(!cartShown)}>
					<div className="left">
						{mobile && (
							<i className={classNames('chevron icon', {
								down: !cartShown,
								up: cartShown
							})}></i>
						)}
						<Header text='Carrito' centered={false} />
					</div>
					<Header text={`$${addCommas(getTotal())}`} size='small' className='total' />
				</div>
				{(!mobile || cartShown) && <>
					<div className="items">
						{shown_cart.map((a,i)=>(
							<CartItem
								showSize
								key={`CRTI-${a.product_id}-${i}`}
								product={a}
								amount={a.quantity}
								image={`${CDN_URL}/products/${a.product_id}/0.webp`}
								actions={<>
									<div style={{ marginTop: 5 }} />
									{a.quantity>1 && (
										<div className="meta">
											Precio: ${addCommas(a.price)}
										</div>
									)}
									<div className="meta" style={{ marginBottom: 5 }}>
										Cantidad: {addCommas(a.quantity, true, true)}
									</div>
								</>}
							/>
						))}
					</div>
					<div className="totals">
						<div className="amount">
							<div className="label">Productos</div>
							<div className="value">${addCommas(order_items_total)}</div>
						</div>
						<div className="amount">
							<div className="label">Entrega</div>
							<div className="value">
								{discounted_shipping ? (
									`$${addCommas(free_shipping ? 0 : webdata?.variables.shipping_cost)}`
								) : (checkoutOrder || (selected_shipping_method && !selected_shipping_method.local)) && !(stage===CheckoutStage.SHIPPING && checkoutOrder && checkoutOrder.shipping_method_local) ? (
									`$${addCommas((checkoutOrder?.shipping_cost!==null && typeof checkoutOrder?.shipping_cost!=='undefined') ? checkoutOrder?.shipping_cost : (selected_shipping_method?.cost || 0))}`
								) : (
									<Placeholder height={16} style={{ backgroundColor: '#E0E0E0' }} width={30} />
								)}
							</div>
						</div>
						<div className="amount final">
							<div className="label">{checkoutOrder && stage===CheckoutStage.PAYMENT ? 'Total' : 'Subtotal'}</div>
							<div className="value">${addCommas(getTotal())}</div>
						</div>
					</div>
				</>}
			</>}
		</div>
	</div>
}

export default Checkout;