import React, { useEffect, useState } from 'react';
import { Icon } from 'react-frontier';

interface QuantityProps{
	quantity: number,
	onChange: (q: number)=>void,
	format?: (v: number)=>string,
	style?: React.CSSProperties,
}

var Quantity = (props: QuantityProps)=>{
	return <div className="co quantity">
		<div className="left button" onClick={()=>props.onChange(Math.max(props.quantity-1, 0))}>
			<Icon name='minus' />
		</div>
		<div className="text">
			{props.format ? props.format(props.quantity) : props.quantity}
		</div>
		<div className="right button" onClick={()=>props.onChange(props.quantity+1)}>
			<Icon name="plus" />
		</div>
	</div>
}

export default Quantity;